define("discourse/plugins/discourse-adplugin/discourse/components/house-ad", ["exports", "@ember/component", "@ember/utils", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _utils, _decorators, _adComponent, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showAd}}
    {{html-safe adHtml}}
  {{/if}}
  */
  {
    "id": "r15k8uuj",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"adHtml\"]]],null]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showAd` property path was used in the `discourse/plugins/discourse-adplugin/discourse/components/house-ad.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showAd}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `adHtml` property path was used in the `discourse/plugins/discourse-adplugin/discourse/components/house-ad.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.adHtml}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/house-ad.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  const adIndex = {
    topic_list_top: null,
    topic_above_post_stream: null,
    topic_above_suggested: null,
    post_bottom: null,
    topic_list_between: null
  };
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _adComponent.default.extend((_dec = (0, _decorators.default)("placement", "showAd"), _dec2 = (0, _decorators.default)("showToGroups", "showAfterPost", "showAfterTopicListItem", "showOnCurrentPage"), _dec3 = (0, _decorators.default)("postNumber", "placement"), _dec4 = (0, _decorators.default)("placement"), (_obj = {
    classNames: ["house-creative"],
    classNameBindings: ["adUnitClass"],
    attributeBindings: ["colspanAttribute:colspan"],
    adHtml: "",
    colspanAttribute() {
      return this.tagName === "td" ? "5" : null;
    },
    adUnitClass(placement, showAd) {
      return showAd ? `house-${placement}` : "";
    },
    showAd(showToGroups, showAfterPost, showAfterTopicListItem, showOnCurrentPage) {
      return showToGroups && (showAfterPost || showAfterTopicListItem) && showOnCurrentPage;
    },
    showAfterPost(postNumber, placement) {
      if (!postNumber && placement !== "topic-list-between") {
        return true;
      }
      return this.isNthPost(parseInt(this.site.get("house_creatives.settings.after_nth_post"), 10));
    },
    showAfterTopicListItem(placement) {
      if (placement !== "topic-list-between") {
        return true;
      }
      return this.isNthTopicListItem(parseInt(this.site.get("house_creatives.settings.after_nth_topic"), 10));
    },
    chooseAdHtml() {
      const houseAds = this.site.get("house_creatives"),
        placement = this.get("placement").replace(/-/g, "_"),
        adNames = this.adsNamesForSlot(placement);

      // filter out ads that should not be shown on the current page
      const filteredAds = adNames.filter(adName => {
        const ad = houseAds.creatives[adName];
        return ad && (!ad.category_ids?.length || ad.category_ids.includes(this.currentCategoryId));
      });
      if (filteredAds.length > 0) {
        if (!adIndex[placement]) {
          adIndex[placement] = 0;
        }
        let ad = houseAds.creatives[filteredAds[adIndex[placement]]] || "";
        adIndex[placement] = (adIndex[placement] + 1) % filteredAds.length;
        return ad.html;
      }
    },
    adsNamesForSlot(placement) {
      const houseAds = this.site.get("house_creatives");
      if (!houseAds || !houseAds.settings) {
        return [];
      }
      const adsForSlot = houseAds.settings[placement];
      if (Object.keys(houseAds.creatives).length > 0 && !(0, _utils.isBlank)(adsForSlot)) {
        return adsForSlot.split("|");
      } else {
        return [];
      }
    },
    refreshAd() {
      this.set("adHtml", this.chooseAdHtml());
    },
    didInsertElement() {
      this._super(...arguments);
      if (!this.get("showAd")) {
        return;
      }
      if (adIndex.topic_list_top === null) {
        // start at a random spot in the ad inventory
        const houseAds = this.site.get("house_creatives");
        Object.keys(adIndex).forEach(placement => {
          const adNames = this.adsNamesForSlot(placement);
          if (adNames.length === 0) {
            return;
          }
          // filter out ads that should not be shown on the current page
          const filteredAds = adNames.filter(adName => {
            const ad = houseAds.creatives[adName];
            return ad && (!ad.category_ids?.length || ad.category_ids.includes(this.currentCategoryId));
          });
          adIndex[placement] = Math.floor(Math.random() * filteredAds.length);
        });
      }
      this.refreshAd();
    }
  }, (_applyDecoratedDescriptor(_obj, "colspanAttribute", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "colspanAttribute"), _obj), _applyDecoratedDescriptor(_obj, "adUnitClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "adUnitClass"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj), _applyDecoratedDescriptor(_obj, "showAfterTopicListItem", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showAfterTopicListItem"), _obj)), _obj))));
});