define("discourse/plugins/discourse-adplugin/discourse/initializers/initialize-ad-plugin", ["exports", "discourse/lib/plugin-api", "discourse/models/site", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _pluginApi, _site, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "initialize-ad-plugin",
    initialize(container) {
      (0, _renderGlimmer.registerWidgetShim)("after-post-ad", "div.widget-connector", (0, _templateFactory.createTemplateFactory)(
      /*
        <PostBottomAd @model={{@data}} />
      */
      {
        "id": "WMP4Ly2R",
        "block": "[[[8,[39,0],null,[[\"@model\"],[[30,1]]],null]],[\"@data\"],false,[\"post-bottom-ad\"]]",
        "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-adplugin/discourse/initializers/initialize-ad-plugin.js",
        "isStrictMode": false
      }));
      (0, _pluginApi.withPluginApi)("0.1", api => {
        api.decorateWidget("post:after", helper => {
          return helper.attach("after-post-ad", helper.widget.model);
        });
      });
      const messageBus = container.lookup("service:message-bus");
      const currentUser = container.lookup("service:current-user");
      const channel = currentUser ? "/site/house-creatives/logged-in" : "/site/house-creatives/anonymous";
      messageBus.subscribe(channel, function (houseAdsSettings) {
        _site.default.currentProp("house_creatives", houseAdsSettings);
      });
    }
  };
});